import {Injectable} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {map, Observable, of} from 'rxjs';
import {IBlogDT} from '../types/blog-d-t';
import blog_data from '../data/blog-data';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(
    private router: Router
  ) {
    this.router.events.subscribe({
      next:event => {
        if (event instanceof NavigationEnd) {
          this.openMobileMenus = false;
        }
      }
    });
  }

  public openMobileMenus: boolean = false;

  // Get blogs
  public get blogs(): Observable<IBlogDT[]> {
    return of(blog_data);
  }

  // Get blog By id
  public getBlogById(id: string): Observable<IBlogDT | undefined> {
    return this.blogs.pipe(map(items => {
      return items.find(p => Number(p.id) === Number(id));
    }));
  }

  public scrollToFragment(site:string ,fragment: string): void {
    this.router.navigate([site], {fragment: fragment});
    setTimeout(() => {
      const element = document.getElementById(fragment);
      if (element) {
        const offsetTop = element.offsetTop - 120;
        console.log('element offset', element, offsetTop);
        window.scrollTo({top: offsetTop, behavior: 'smooth'});
      }
    }, 100);
  }

}
