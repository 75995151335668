import {NgModule} from '@angular/core';
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FooterComponent} from "./layout/footer/footer.component";
import {MobileOffcanvasComponent} from "./components/mobile-offcanvas/mobile-offcanvas.component";
import {FaqComponent} from "./components/faq/faq.component";
import {PartnersComponent} from "./components/partners/partners.component";
import {ToolsComponent} from "./components/tools/tools.component";
import {HeaderComponent} from "./layout/header/header.component";
import {CountdownTimerComponent} from "./components/countdown-timer/countdown-timer.component";
import {DocumentAreaComponent} from "./components/document-area/document-area.component";
import {BackToTopComponent} from "./components/back-to-top/back-to-top.component";
import {DragdropDirective} from "./directives/dragdrop.directive";
import {CountUpDirective} from "./directives/count-up/count-up.directive";
import {ErrorCodeComponent} from "./components/error-code/errorCode.component";


@NgModule({
  exports: [
    HeaderComponent,
    ToolsComponent,
    PartnersComponent,
    FaqComponent,
    FooterComponent,
    ErrorCodeComponent,
    MobileOffcanvasComponent,
    BackToTopComponent,
    CountdownTimerComponent,
    DocumentAreaComponent,
    DragdropDirective,
    CountUpDirective
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  declarations: [
    HeaderComponent,
    ToolsComponent,
    PartnersComponent,
    FaqComponent,
    FooterComponent,
    MobileOffcanvasComponent,
    BackToTopComponent,
    CountdownTimerComponent,
    DocumentAreaComponent,
    ErrorCodeComponent,
    DragdropDirective,
    CountUpDirective
  ]
})
export class SharedModule {
}
