import {Component} from '@angular/core';
import {UtilsService} from "../../services/utils.service";
import {Router} from "@angular/router";

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrl: './footer.component.scss'
})
export class FooterComponent {

    public date = new Date().getFullYear();

  constructor(
    public utilsService: UtilsService,
    private router: Router
  ) {}

  scrollToFragment(page:string, fragment: string): void {
    this.utilsService.scrollToFragment(page,fragment);
  }

  isFAQRouteActive(): boolean {
    return this.router.url === '/home#faq';
  }

  isToolsRouteActive(): boolean {
    return this.router.url === '/home#tools';
  }
}
