import { IBlogDT } from "../types/blog-d-t";

const blog_data:IBlogDT[] = [
  {
    id: 1,
    title: 'The Power of Blockchain don\'t Miss Out on Our ICO',
    img: '/assets/img/blog/blog_masonry01.jpg',
    date: '2024/03/15',
    desc:"Our ICO is not just about financial gains; it's about being part of a movement that is shaping the future. " +
      "By investing in our ICO, you become an integral part of a community that believes in the power",
    author:'/assets/img/blog/blog_author01.png',
    author_name:'Tom Cruise',
    comment: 7,
    view: 1752,
    blog_masonry:true,
  },
  {
    id: 2,
    title: 'Invest in the Next Big Thing discover Our ICO Opportunity',
    img: '/assets/img/blog/blog_masonry02.jpg',
    date: '2024/03/15',
    desc:"Our ICO is not just about financial gains; it's about being part of a movement that is shaping the future. " +
      "By investing in our ICO, you become an integral part of a community that believes in the power",
    author:'/assets/img/blog/blog_author01.png',
    author_name:'Max Power',
    comment: 9,
    view: 1752,
    blog_masonry:true,
  },
  {
    id: 3,
    title: 'Maximizing Potential discover the Benefits of Our ICO Investment',
    img: '/assets/img/blog/blog_standard01.jpg',
    date: '2024/04/18',
    desc:"Our ICO presents a unique opportunity for you to be part of the digital revolution " +
      "and tap into the immense potential of blockchain technology.",
    author:'/assets/img/blog/blog_author03.png',
    author_name:'Liam James',
    comment: 7,
    view: 9752,
    blog_standard:true,
  },
  {
    id: 4,
    title: 'Tokenize Your Future explore Our ICO for Game-Changing Investments',
    img: '/assets/img/blog/blog_standard02.jpg',
    date: '2024/05/10',
    desc:"Our ICO presents a unique opportunity for you to be part of the digital revolution " +
      "and tap into the immense potential of blockchain technology.",
    author:'/assets/img/blog/blog_author04.png',
    author_name:'Tom Cruise',
    comment: 7,
    view: 1200,
    blog_standard:true,
  }
]

export default blog_data;
